import React from 'react';
import './App.css';

function App() {
  return (
    <main className='portfolio'>
      <header className='header'>
        <h1>Jacob Jordan's Portfolio</h1>
      </header>
      <section className='portfolio-item'>
        <article className='item'>
          <div>
            <h2>About</h2>
            <p>
            I combine technical expertise with a strong user-focused mindset, blending problem-solving skills with a deep empathy for end-users. This approach allows me to create intuitive, user-friendly interfaces that enhance the overall product experience. I strive to go beyond the technical side of development, ensuring that the user's journey is at the forefront of my work.
            </p>
            <p>
            My diverse experience, spanning freelancing, small businesses, and large corporate environments like Walmart, has made me highly adaptable. I thrive in a variety of settings and bring flexibility to every project, making me well-suited for roles that require cross-functional collaboration and leadership.
            </p>
          </div>
        </article>
        <article className='item'>
            <img src='./images/jj.jpg' alt='Jacob Jordan'/>
        </article>
      </section>
      <section className='portfolio-banner'>
        <article>
            <h2>Work</h2>
            <p>I've had the opportunity to work with a small business, DATA Inc., Walmart, as well as freelance. At DATA Inc., I supported government and commercial clients in education, engineering, and healthcare. At Walmart, I contributed to the pharmacy division by developing applications to improve operational efficiency.
            </p>
        </article>
      </section>
      <section className='portfolio-item alternate'>
        <article className='item'>
          <div>
            <h2>Walmart</h2>
            <p>At Walmart, I had the opportunity to design, develop, architect, teach, and, most importantly, learn. I collaborated with talented engineers and worked within a top-tier tech infrastructure, gaining invaluable experience. I learned the intricacies of building software at the scale of a Fortune 1 company, and without a doubt, my time at Walmart has been the most educational and rewarding of my career so far.</p>
          </div>
        </article>
        <article className='item'>
          <img src='./images/walmart-tile.jpg' alt='Walmart headquarters with an Amercian flag.'/>
        </article>
      </section>

      <section className='portfolio-item'>
        <article className='item'>
          <div>
            <h2>DATA Inc.</h2>
            <p>DATA Inc. primarily serves government and commercial professionals in the education, 
            engineering, and medical sectors.
            I was responsible for the complete development of this project. The tech stack used was Kentico CMS, ASP.NET Core MVC, SQL Server, HTML, and CSS.</p>
            <a href='https://www.datausa.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
          <a href='https://www.datausa.com/' target='_blank' rel='noopener noreferrer'>
            <img src='./images/data-tile.jpg' alt='DATA Inc Website'/>
          </a>
        </article>
      </section>
      <section className='portfolio-item alternate'>
        <article className='item'>
          <div>
            <h2>Coverys</h2>
            <p>Coverys is a national leader in medical professional liability insurance.
              I was responsible for the front end development of this project. The tech stack used was Kentico CMS, ASP.NET Core MVC, SQL Server, HTML, and CSS.
            </p>
            <a href='https://www.coverys.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
          <a href='https://www.coverys.com/' target='_blank' rel='noopener noreferrer'>
            <img src='./images/coverys-tile.jpg' alt='Coverys website'/>
          </a>
        </article>
      </section>
      <section className='portfolio-item'>
        <article className='item'>
          <div>
            <h2>Labral Reconstruction</h2>
            <p>Labral Reconstruction is a brochure site that was built for an Orthopaedic surgeon that invented this procedure.
            I was responsible for the complete development of their brochure site, built using Kentico CMS, ASP.NET Core MVC, SQL Server, HTML, and CSS. </p>
            <a href='https://www.labralreconstruction.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
          <a href='https://www.labralreconstruction.com/' target='_blank' rel='noopener noreferrer'>
            <img src='./images/labral-tile.jpg' alt='Labral Reconstruction Website'/>
          </a>
        </article>
      </section>
      
      <section className='portfolio-tech'>
        <ul>
        <li>
            <div>Next.js</div>
            <img src='./images/nextjs.png' alt='Next js logo' />
          </li>
          <li>
            <div>React</div>
            <img src='./images/react.png' alt='React logo' />
          </li>
          <li>
            <div>HTML 5</div>
            <img src='./images/html5.png' alt='HTML 5 logo' />
          </li>
          <li>
            <div>CSS 3</div>
            <img src='./images/css3.png' alt='CSS 3 logo' />
          </li>
          <li>
            <div>Kentico</div>
            <img src='./images/kentico.png' alt='Kentico logo' />
          </li>
          <li>
            <div>ASP.NET</div>
            <img src='./images/asp.png' alt='ASP.NET logo' />
          </li>
          <li>
            <div>.NET Core</div>
            <img src='./images/dotnetcore.png' alt='.NET Core logo' />
          </li>
          <li>
            <div>SQL Server</div>
            <img src='./images/sqlserver.png' alt='SQL Server logo' />
          </li>
          <li>
            <div>IIS</div>
            <img src='./images/iis.png' alt='Microsoft IIS logo' />
          </li>
          <li>
            <div>Docker</div>
            <img src='./images/docker.png' alt='Docker logo' />
          </li>
          <li>
            <div>Kubernetes</div>
            <img src='./images/kubernetes.png' alt='Kubernetes logo' />
          </li>
          <li>
            <div>Azure</div>
            <img src='./images/azure.png' alt='Azure logo' />
          </li>
          <li>
            <div>Git</div>
            <img src='./images/git.png' alt='Git logo' />
          </li>
          <li>
            <div>GitHub</div>
            <img src='./images/github.png' alt='GitHub logo' />
          </li>
          <li>
            <div>Jenkins</div>
            <img src='./images/jenkins.png' alt='Jenkins logo' />
          </li>
          <li>
            <div>Node</div>
            <img src='./images/node.png' alt='Node logo' />
          </li>
          <li>
            <div>Webpack</div>
            <img src='./images/webpack.png' alt='Webpack logo' />
          </li>
          <li>
            <div>Gulp</div>
            <img src='./images/gulp.png' alt='Gulp logo' />
          </li>
          <li>
            <div>Cypress</div>
            <img src='./images/cypress.png' alt='Cypress logo' />
          </li>
          <li>
            <div>Jquery</div>
            <img src='./images/jquery.png' alt='Jquery logo' />
          </li>
        </ul>
      </section>
      <section className='portfolio-item alternate'>
        <article className='item'>
          <div>
            <h2>ServSafe</h2>
            <p>ServSafe is a food and beverage safety training and certificate program administered by the National Restaurant Association. Sanitation certification is required by 
              most restaurants as a basic credential for their management staff. The goal was to create a web app using Kentico that would help restaurants get certified. I was 
              tasked with the front-end and Kentico development.</p>
            <a href='https://www.servsafe.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
          <a href='https://www.servsafe.com/' target='_blank' rel='noopener noreferrer'>
            <img src='./images/servsafe-tile.jpg' alt='ServSafe website'/>
          </a>
        </article>
      </section>
      <section className='portfolio-item'>
        <article className='item'>
          <div>
            <h2>NRA Show</h2>
            <p>Every year the National Restaurant Association has a conference that features more than 40,000 restaurant industry executives. The goal was to create a web app using Kentico 
              and Angular to help manage and display all of the conference data. I was tasked with the front-end development, Kentico development, and creating an API using ASP.NET.</p>
            <a href='https://www.nationalrestaurantshow.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
          <a href='https://www.nationalrestaurantshow.com/' target='_blank' rel='noopener noreferrer'>
            <img src='./images/nra-tile.jpg' alt='NRA show website'/>
          </a>
        </article>
      </section>
      <section className='portfolio-item alternate'>
        <article className='item'>
          <div>
            <h2>Coverys International</h2>
            <p>Coverys International Insurance Company DAC trading as Coverys 
              International is an AM Best A rated company and part of the Coverys Group, a leading provider of medical professional liability insurance. 
              I was responsible for the complete development of their brochure site, built using Next.js, CSS Modules and HTML.</p>
            <a href='https://www.coverysinternational.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
        <a href='https://www.coverysinternational.com/' target='_blank' rel='noopener noreferrer'>
          <img src='./images/coverys-international-tile.jpg' alt='Coverys International Website'/>
        </a>
        </article>
      </section>
      <section className='portfolio-item'>
        <article className='item'>
          <div>
            <h2>Proxess</h2>
            <p>Proxess is a startup specializing in electronic access control solutions for the security industry. I was responsible for the complete development of their brochure site, built using Kentico CMS, ASP.NET Core MVC, SQL Server, HTML, and CSS.</p>
            <a href='https://www.proxess.com/' target='_blank' rel='noopener noreferrer'>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" style={{fontSize: '12'}}>
                <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
              </svg>
              View Live Project
            </a>
          </div>
        </article>
        <article className='item'>
          <a href='https://www.proxess.com/' target='_blank' rel='noopener noreferrer'>
            <img src='./images/proxess-tile.jpg' alt='Proxess website'/>
          </a>
        </article>
      </section>
      <section className='portfolio-banner alternate'>
        <article>
            <h2>Results</h2>
            <p>As a developer, I've thoroughly enjoyed building user interfaces alongside amazing teams while continually learning new technologies. Collaborating with a team drives me to push the boundaries of development and deliver high-quality solutions.</p>
        </article>
      </section>
      <footer>

      </footer>
    </main>
  );
}

export default App;
